import { request, noTimeOutReq } from "@/utils/request.js";

//分页条件查询考试考生信息和视频信息
export function examineeMsgPage(examId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/score/video/page/list/${examId}/${pageIndex}/${pageSize}`,
    method: "post",
    data,
  });
}

//考试批次
export function examTimeSession(examId, subjectType) {
  return request({
    url: `/exam/examinee/video/list/exam/time/session/${examId}/${subjectType}`,
    method: "post",
  });
}

//取消考试考生的考试资格
export function updateExamineeStatus(examExamineeId, data) {
  return request({
    url: `/exam/score/video/update/examineeStatus/${examExamineeId}`,
    method: "post",
    data,
  });
}

// 视频重新上传
export function uploadVideo(data) {
  return noTimeOutReq({
    url: `/exam/examinee/video/oss/examinee/upload`,
    headers: { "Content-Type": "multipart/form-data" },
    method: "post",
    data,
  });
}

//分页条件查询考试考生信息和图片信息
export function examineePicMsgPageAPI(examId, pageIndex, pageSize,data) {
  return request({
    url: `/exam/score/video/examineeAnswerRecord/examExamineAnswerRecordPictureDetailList/${examId}/${pageIndex}/${pageSize}`,
    method: "post",
    data
  });
}

// 图片重新上传
export function uploadPictureAPI(data){
  return request({
    url:"/exam/examinee/answer/data/type/examinee/upload",
    headers: { "Content-Type": "multipart/form-data" },
    method: "post",
    data,
  })
}