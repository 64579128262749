<template>
  <div>
    <el-row style="margin-bottom: 10px">
      <el-button
        v-throttle
        size="medium"
        @click="
          () => {
            this.$router.back();
          }
        "
        >返回</el-button
      >
    </el-row>
    <el-form :inline="true" :model="examineePicMsgCondition">
      <!-- 考试学科 -->
      <el-form-item label="科目" class="formItemBoxStyle">
        <el-select
          v-model="examineePicMsgCondition.subjectType"
          placeholder="全部"
          style="margin-right: 20px; width: 150px"
          @change="reloadSubjectType"
        >
          <el-option
            v-for="val in examineePicMsgConditionList.subjectType"
            :key="val"
            :label="val | subjectTypeFilters"
            :value="val"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- 考试批次 -->
      <el-form-item label="考试批次" class="formItemBoxStyle">
        <el-select
          v-model="examineePicMsgCondition.examSession"
          placeholder="全部"
          style="margin-right: 20px; width: 150px"
          @change="reloadSession"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            v-for="(val, i) in examineePicMsgConditionList.examSession"
            :key="i"
            :label="val"
            :value="val"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- 考生学号 -->
      <el-form-item label="考生编号" class="formItemBoxStyle">
        <el-input
          v-model="examineePicMsgCondition.examineeCode"
          placeholder="请输入考生编号"
          clearable
          style="width: 290px; margin-right: 20px"
          @input="reloadExamineePicMsg"
        ></el-input>
      </el-form-item>

      <!-- 显微镜状态  -->
      <el-form-item label="显微镜状态" class="formItemBoxStyle">
        <el-select
          v-model="examineePicMsgCondition.microScopePictureStatus"
          style="margin-right: 20px; width: 150px"
          @change="reloadMicroStatus"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option label="正常" :value="2"></el-option>
          <el-option label="异常" :value="3"></el-option>
        </el-select>
      </el-form-item>

      <!-- 试卷拍照状态  -->
      <el-form-item label="试卷拍照状态" class="formItemBoxStyle">
        <el-select
          v-model="examineePicMsgCondition.answerHtmlImgStatus"
          style="margin-right: 20px; width: 150px"
          @change="reloadMicroStatus"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option label="正常" :value="2"></el-option>
          <el-option label="未上传" :value="3"></el-option>
        </el-select>
      </el-form-item>

      <!-- 电子试卷状态 -->
      <el-form-item label="电子试卷状态" class="formItemBoxStyle">
        <el-select
          style="margin-right: 20px; width: 150px"
          v-model="examineePicMsgCondition.reportAddrStatus"
          @change="reloadMicroStatus"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option label="正常" :value="2"></el-option>
          <el-option label="异常" :value="3"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!-- 表格区域 -->
    <el-table
      border
      :data="examineePicMsgData"
      tooltip-effect="dark"
      style="width: 100%"
      :header-cell-style="{
        'text-align': 'center',
        backgroundColor: '#dfe6ec',
      }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column
        prop="examSession"
        label="考试批次"
        width="80"
        center
      ></el-table-column>

      <el-table-column prop="examTime" label="考试时间" center>
        <template slot-scope="scope">
          {{ scope.row.examTime | dayList }}
        </template>
      </el-table-column>

      <el-table-column prop="examineeName" label="学生姓名"></el-table-column>

      <el-table-column prop="examineeCode" label="考生编号"></el-table-column>

      <el-table-column label="科目">
        <template>
          <!-- {{ "..." }} -->
          {{ examineePicMsgCondition.subjectType | subjectTypeFilters }}
        </template>
      </el-table-column>

      <el-table-column label="显微镜截图">
        <template slot-scope="scoped">
          <span v-if="scoped.row.microscopePictureStatus === 1">/</span>
          <span
            v-else-if="scoped.row.microscopePictureStatus === 2"
            style="color: green"
            >正常</span
          >
          <span v-else style="color: red">异常</span>
        </template>
      </el-table-column>

      <el-table-column label="试卷拍照状态">
        <template slot-scope="scoped">
          <span v-if="scoped.row.answerHtmlImgStatus === 1">/</span>
          <span
            v-else-if="scoped.row.answerHtmlImgStatus === 2"
            style="color: green"
            >正常</span
          >
          <span v-else style="color: red">未上传</span>
        </template>
      </el-table-column>

      <el-table-column label="电子试卷状态">
        <template slot-scope="scoped">
          <span v-if="scoped.row.reportAddrStatus === 1">/</span>
          <span
            v-else-if="scoped.row.reportAddrStatus === 2"
            style="color: green"
            >正常</span
          >
          <span v-else style="color: red">异常</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="900">
        <template slot-scope="scoped">
          <el-button
            v-throttle
            type="success"
            @click="
              handleCheckPicDetail(
                scoped.row.microscopePictureAddr,
                '显微镜截图'
              )
            "
            :disabled="
              scoped.row.subjectType == 1 ||
              scoped.row.microscopeStatus == 0 ||
              scoped.row.microscopePictureStatus == 1
            "
            >查看显微镜截图</el-button
          >
          <el-button
            v-throttle
            type="primary"
            @click="
              handleCheckPicDetail(scoped.row.answerHtmlImgAddr, '试卷拍照')
            "
            >查看试卷拍照</el-button
          >
          <!-- 解开disable <el-button
            v-throttle
            type="primary"
            @click="
              handleCheckPicDetail(scoped.row.answerHtmlImgAddr, '试卷拍照')
            "
            :disabled="
              scoped.row.answerHtmlImgStatus == 1
            "
            >查看试卷拍照</el-button
          > -->
          <el-button
            type="warning"
            v-throttle
            @click="handleCheckPicDetail(scoped.row.reportAddr, '电子试卷')"
            :disabled="scoped.row.reportAddrStatus == 1 || scoped.row.paperLess == 0"
            >查看电子试卷</el-button
          >
          <el-button
            v-throttle
            type="success"
            @click="reloadMicroPic(scoped.row)"
            :disabled="
              scoped.row.subjectType == 1 ||
              scoped.row.microscopeStatus == 0 ||
              scoped.row.microscopePictureStatus == 1
            "
            >重传显微镜截图</el-button
          >
          <!-- 解开disable <el-button
            v-throttle
            type="primary"
            @click="reloadTestPaperPic(scoped.row)"
            :disabled="
              scoped.row.answerHtmlImgStatus == 1 || scoped.row.paperLess == 0
            "
            >重传试卷拍照</el-button
          > -->
          <el-button
            v-throttle
            type="primary"
            @click="reloadTestPaperPic(scoped.row)"
            >重传试卷拍照</el-button
          >
          <el-button
            type="warning"
            v-throttle
            :disabled="scoped.row.reportAddrStatus == 1"
            @click="reloadElectronicPaperBtnClick(scoped.row)"
            >重传电子试卷</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页组件 -->
    <el-row style="margin-top: 20px; margin-bottom: 20px">
      <el-pagination
        @size-change="handleSizeChangeExamineePicMsgPage"
        @current-change="handleCurrentChangeExamineePicMsgPage"
        :current-page="examineePicMsgPage.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="examineePicMsgPage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="examineePicMsgPage.totalSize"
      ></el-pagination>
    </el-row>

    <!-- 显微镜截图上传弹框 -->
    <el-dialog
      width="650px"
      :title="`上传${
        isMicroOrPic === 0
          ? '显微镜截图'
          : isMicroOrPic === 1
          ? '试卷图片'
          : '电子试卷'
      }`"
      :visible.sync="microscopeBtnDialog"
      append-to-body
      top="22vh"
    >
      <!-- 上传组件 -->
      <el-upload
        class="upload-demo"
        drag
        accept=".jpeg, .png, .webp, .tiff, .psd, .svg"
        action="https://jsonplaceholder.typicode.com/posts/"
        multiple=""
        :show-file-list="false"
        :http-request="microPicUploadFunc"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          <div>将文件拖到此处，或<em>点击上传</em></div>
          <div style="margin-top: 5px; color: rgb(170, 170, 170)">
            支持 png、jpg 格式，仅限上传5张
          </div>
        </div>
      </el-upload>
      <!-- 标签页组件 -->
      <el-tabs
        v-model="microscopeActiveName"
        @tab-click="handleClick"
        style="margin-top: 10px"
      >
        <el-tab-pane
          :label="`正在上传(${uploadingPic ? uploadingPic.length : 0})`"
          name="loading"
        >
          <el-table
            :data="uploadingPic"
            style="width: 100%"
            size="medium"
            :header-cell-style="() => 'background-color:rgb(248,248,250)'"
          >
            <el-table-column
              prop="name"
              label="名称"
              width="280"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="size" label="大小" width="160">
            </el-table-column>
            <el-table-column prop="progress" label="进度" width="160">
              <template slot-scope="scope">
                <el-progress
                  :percentage="scope.row.progress"
                  :format="microPicUploadformat"
                  :color="setProgressStatus"
                ></el-progress>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane
          :label="`完成上传(${handleUploadPic ? handleUploadPic.length : 0})`"
          name="loaded"
        >
          <el-table
            :data="handleUploadPic"
            style="width: 100%"
            size="medium"
            :header-cell-style="() => 'background-color:rgb(248,248,250)'"
          >
            <el-table-column
              prop="name"
              label="名称"
              width="280"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="size" label="大小" width="160">
            </el-table-column>
            <el-table-column prop="progress" label="状态" width="160">
              <div style="display: flex">
                <div>
                  <img
                    style="width: 18px; height: 18px; margin-right: 3px"
                    src="./img/dataNormal.png"
                  />
                </div>

                <div>上传成功</div>
              </div>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div class="button-container">
        <el-button
          type="primary"
          v-throttle
          @click="closeUploadMicroDialog"
          style="width: 150px"
          :disabled="testComputedData"
          >完成</el-button
        >
      </div>
    </el-dialog>

    <!-- 查看图片 -->
    <el-row>
      <pictureView
        v-if="showPictures"
        :on-close="closeViewer"
        :url-list="picList"
        fullScreen
      >
      </pictureView>
    </el-row>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  examTimeSession,
  uploadPictureAPI,
  examineePicMsgPageAPI,
} from "@/api/marking/abnormal.js";
import pictureView from "@/components/pictureView";
export default {
  name: "picHandleDetail",
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
    pictureView,
  },
  data() {
    return {
      // 分页条件查询考试考生信息和图片信息
      examineePicMsgCondition: {
        examSession: 0,
        examSysOrgAreaId: 0,
        examSysOrgExamPlaceId: 0,
        examSysOrgExamPlaceRoomId: 0,
        examineeCode: "",
        examineeStatus: 0,
        examineeVideoStatus: 0,
        microScopePictureStatus: 0, // 显微镜截图状态
        orgAreaId: 0,
        orgSchoolId: 0,
        studentCode: "",
        studentName: "",
        subjectType: 0,
        answerHtmlImgStatus: 0,
        reportAddrStatus: 0,
      },
      // 分页可选文件
      examineePicMsgConditionList: {
        subjectType: [], // 考斯学科
        examSession: [], // 考试批次
        examId: "", // 考试 id
      },
      examineePicMsgData: [], // 考生表格数据
      // 分页数据初始化
      examineePicMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      // 重传显微镜截图弹框
      microscopeBtnDialog: false,
      microscopeActiveName: "loading",
      showPictures: false,
      tempExamineeId: "",
      picList: [],
      isMicroOrPic: 0, // 0表示为显微镜截图弹框，1则为试卷图片弹框
      uploadPictureData: {},
      uploadingPic: [], // 正在上传的数组
      handleUploadPic: [], // 已经完成上传的数组
      fileListObj: {},

      uploadingPaper: [], // 正在上传的电子试卷数组
      handleUploadPaper: [], // 已经完成上传的电子试卷数组
    };
  },
  mounted() {
    let examInfo = JSON.parse(this.$route.query.examInfo); // 获取导航栏中的考试信息
    this.examineePicMsgConditionList.subjectType = examInfo.subjectTypes;
    this.examineePicMsgConditionList.examId = examInfo.examId;
    this.examineePicMsgCondition.subjectType = examInfo.subjectTypes[0];

    this.getExamineePicMsgData(examInfo.examId, {
      subjectType: this.examineePicMsgCondition.subjectType,
    });

    // 调用此方法，完成考试批次数据的获取
    this.examTimeSessionFunc(
      this.examineePicMsgConditionList.examId,
      this.examineePicMsgCondition.subjectType
    );
  },
  computed: {
    isCompleteBtnDisable: function () {
      return false;
    },
    testComputedData: function () {
      let result = false;
      if (this.uploadingPic && this.uploadingPic.length > 0) {
        this.uploadingPic.forEach((item) => {
          if (item.progress !== 100) {
            result = true;
          }
        });
      }
      return result;
    },
  },
  methods: {
    // 获取考试批次
    examTimeSessionFunc(examId, subjectType) {
      examTimeSession(examId, subjectType)
        .then((res) => {
          if (res.success) {
            // console.log(res.data.map(it=>it.examSession))
            this.examineePicMsgConditionList.examSession = [
              ...new Set(res.data.map((it) => it.examSession)),
            ];
            // res.data.forEach((val) => {
            //   this.examineePicMsgConditionList.examSession.push(
            //     val.examSession
            //   );
            // });
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 获取考生显微镜和试卷图片数据
    getExamineePicMsgData(
      examId = this.examineePicMsgConditionList.examId,
      data = this.examineePicMsgCondition
    ) {
      // 在这里调用接口，分页获取考生图片数据
      // 图片接口还没完成，所以就想使用视频接口，等后续图片接口完成后再使用
      examineePicMsgPageAPI(
        examId,
        this.examineePicMsgPage.pageIndex,
        this.examineePicMsgPage.pageSize,
        data
      )
        .then((res) => {
          // console.log(res);
          const { data } = res;
          if (res.success) {
            this.examineePicMsgData = data;
            this.examineePicMsgPage.totalSize = res.total;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          this.$message.info("请求失败");
        });
    },
    // 选择考试学科
    reloadSubjectType(val) {
      this.reloadExamineePicMsg();
    },
    // 选择考试批次
    reloadSession(val) {
      this.examineePicMsgCondition.examineeCode = "";
      this.reloadExamineePicMsg();
    },
    // 重置表单方法
    reloadExamineePicMsg() {
      this.examineePicMsgPage.pageIndex = 1;
      this.getExamineePicMsgData();
    },
    // 选择显微镜截图或图片状态
    reloadMicroStatus() {
      this.examineePicMsgCondition.examineeCode = "";
      this.reloadExamineePicMsg();
    },
    // 分页切换每页数量
    handleSizeChangeExamineePicMsgPage(val) {
      this.examineePicMsgPage.pageIndex = 1;
      this.examineePicMsgPage.pageSize = val;
      this.getExamineePicMsgData();
    },
    // 分页切换当前页
    handleCurrentChangeExamineePicMsgPage(val) {
      this.examineePicMsgPage.pageIndex = val;
      this.getExamineePicMsgData();
    },
    // 检查试卷截图或显微镜截图
    handleCheckPicDetail(picArr, content) {
      if (!picArr || picArr.length == 0) {
        this.$message({
          message: `暂无${content}`,
          type: "warning",
          center: true,
        });
        return;
      }
      this.picList = [];
      picArr.forEach((item) => {
        this.picList.push(this.$imageAddress(item));
      });
      this.showPictures = true;
    },
    // 重传显微镜截图
    reloadMicroPic(row) {
      this.microscopeActiveName = "loading";
      this.tempExamineeId = row.examineeId;
      this.isMicroOrPic = 0;
      if (this.uploadPictureData[row.examineeId]) {
        this.uploadingPic =
          this.uploadPictureData[row.examineeId].uploadingMicroPic;
        this.handleUploadPic =
          this.uploadPictureData[row.examineeId].uploadedMicroPic;
        // if (this.uploadPictureData[row.examineeId].uploadedMicroPic) {
        //   this.handleUploadPic =
        //     this.uploadPictureData[row.examineeId].uploadedMicroPic;
        // }else {
        //   this.handleUploadPic = [];
        // }
      } else {
        this.uploadingPic = [];
        this.handleUploadPic = [];
      }
      this.microscopeBtnDialog = true;
    },
    // 重传试卷图片
    reloadTestPaperPic(row) {
      this.microscopeActiveName = "loading";
      this.tempExamineeId = row.examineeId;
      this.isMicroOrPic = 1;
      if (this.uploadPictureData[row.examineeId]) {
        this.uploadingPic =
          this.uploadPictureData[row.examineeId].uploadingExamPaperPic;
        this.handleUploadPic =
          this.uploadPictureData[row.examineeId].uploadedExamPaperPic;
      } else {
        this.uploadingPic = [];
        this.handleUploadPic = [];
      }

      this.microscopeBtnDialog = true;
    },
    // 重传电子试卷
    reloadElectronicPaperBtnClick(row) {
      this.microscopeActiveName = "loading";
      this.tempExamineeId = row.examineeId;
      this.isMicroOrPic = 2;
      if (this.uploadPictureData[row.examineeId]) {
        this.uploadingPic =
          this.uploadPictureData[row.examineeId].uploadingElectronicPaper;
        this.handleUploadPic =
          this.uploadPictureData[row.examineeId].uploadedElectronicPaper;
      } else {
        this.uploadingPic = [];
        this.handleUploadPic = [];
      }

      this.microscopeBtnDialog = true;
    },
    // 切换标签页方法（正在上传-> 上传完成）
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    // 显微镜截图上传(未调用接口)
    microPicUploadFunc(item) {
      if (this.uploadingPic && this.uploadingPic.length == 5) {
        this.$message.error("仅限上传5张图片");
        return;
      }
      const whiltList = ["jpg", "png"];
      const fileType = item.file.name.replace(/.+\./, "");
      if (whiltList.indexOf(fileType.toLowerCase()) === -1) {
        this.$message.error("文件格式不正确，请重新上传！");
        return;
      }
      const isOver10M = item.file.size / 1024 / 1024 > 10;
      if (isOver10M) {
        this.$message.error("上传图片大小不能超过 10MB!");
        return;
      }
      // 判断一下是截图还是试卷
      let uploadName = "";
      if (this.isMicroOrPic === 0) {
        uploadName = "uploadingMicroPic";
      } else if (this.isMicroOrPic === 1) {
        uploadName = "uploadingExamPaperPic";
      } else {
        uploadName = "uploadingElectronicPaper";
      }
      // 给表格数据赋值
      if (!this.uploadPictureData[this.tempExamineeId]) {
        this.$set(this.uploadPictureData, this.tempExamineeId, {});
      }
      if (!this.uploadPictureData[this.tempExamineeId][uploadName]) {
        this.$set(this.uploadPictureData[this.tempExamineeId], uploadName, []);
      }
      this.uploadPictureData[this.tempExamineeId][uploadName].push({
        name: this.handleFileName(item.file.name),
        size: this.handleDataSize(item.file.size),
        progress: 0,
      });
      this.uploadingPic =
        this.uploadPictureData[this.tempExamineeId][uploadName];
      let index =
        this.uploadPictureData[this.tempExamineeId][uploadName].length;
      this.addNumTestFunc(index - 1);
      // 给上传图片对象赋值
      if (!this.fileListObj[this.tempExamineeId]) {
        this.$set(this.fileListObj, this.tempExamineeId, {});
      }
      if (!this.fileListObj[this.tempExamineeId][uploadName]) {
        this.$set(this.fileListObj[this.tempExamineeId], uploadName, []);
      }
      this.fileListObj[this.tempExamineeId][uploadName].push(item.file);
    },
    // 显微镜截图上传进度条
    microPicUploadformat(percentage) {
      return percentage === 0 ? "等待中" : `${percentage}%`;
    },
    // 设置进度条的样式
    setProgressStatus(percentage) {
      return percentage < 50 ? "rgb(24,144,255)" : "rgb(1,168,80)";
    },
    // 字符串长度过长，中间省略，后加文件类型
    handleFileName(name) {
      if (name.length > 20) {
        const left = name.substr(0, 16);
        const right = name.split(".")[1];
        return left + "... ." + right;
      }
      return name;
    },
    // 数据大小格式处理方法
    handleDataSize(num) {
      if (num < 1024) {
        return num + "B";
      }
      if (num > 1024 && num < 1024 * 1024) {
        return (num / 1024).toFixed(2) + "KB";
      }
      return (num / 1024 / 1024).toFixed(2) + "MB";
    },
    // 完成按钮，这里发送上传请求
    closeUploadMicroDialog() {
      let uploadName = "";
      let fileType = 0;
      if (this.isMicroOrPic === 0) {
        uploadName = "uploadingMicroPic";
        fileType = 2;
      } else if (this.isMicroOrPic === 1) {
        uploadName = "uploadingExamPaperPic";
        fileType = 5;
      } else {
        uploadName = "uploadingElectronicPaper";
        fileType = 1;
      }
      if (
        !this.fileListObj[this.tempExamineeId][uploadName] ||
        this.fileListObj[this.tempExamineeId][uploadName].length == 0
      ) {
        this.microscopeBtnDialog = false;
        return;
      }
      let fd = new FormData();
      this.fileListObj[this.tempExamineeId][uploadName].forEach((item) => {
        fd.append("fileList", item);
      });
      fd.append("examineeId", this.tempExamineeId);
      fd.append("fileType", fileType);
      uploadPictureAPI(fd).then((res) => {
        // console.log(res);
        if (res.success) {
          this.$message.success("上传成功");
          this.microscopeActiveName = "loaded";
          this.transformUploadingPic(this.tempExamineeId);
          this.$set(this.fileListObj, this.tempExamineeId, {});
          this.getExamineePicMsgData();
          this.microscopeBtnDialog = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    closeViewer() {
      this.picList = [];
      this.showPictures = false;
    },
    // 调用该方法可以让上传进度条自增到100
    addNumTestFunc(key) {
      let uploadName = "";
      if (this.isMicroOrPic === 0) {
        uploadName = "uploadingMicroPic";
      } else if (this.isMicroOrPic === 1) {
        uploadName = "uploadingExamPaperPic";
      } else {
        uploadName = "uploadingElectronicPaper";
      }
      if (
        this.uploadPictureData[this.tempExamineeId] &&
        this.uploadPictureData[this.tempExamineeId][uploadName]
      ) {
        this.uploadPictureData[this.tempExamineeId][uploadName][key].timer =
          setInterval(() => {
            this.uploadPictureData[this.tempExamineeId][uploadName][
              key
            ].progress += Math.floor(Math.random() * 20);
            if (
              this.uploadPictureData[this.tempExamineeId][uploadName][key]
                .progress >= 100
            ) {
              this.uploadPictureData[this.tempExamineeId][uploadName][
                key
              ].progress = 100;
              clearInterval(
                this.uploadPictureData[this.tempExamineeId][uploadName][key]
                  .timer
              );
            }
          }, 50);
      }
    },
    // 调用该方法可以将正在上传的数组变成完成上传的数组
    transformUploadingPic(examineeId) {
      // 判断一下是截图还是试卷
      let uploadedName = "";
      let uploadingName = "";
      if (this.isMicroOrPic === 0) {
        uploadedName = "uploadedMicroPic";
        uploadingName = "uploadingMicroPic";
      } else if (this.isMicroOrPic === 1) {
        uploadedName = "uploadedExamPaperPic";
        uploadingName = "uploadingExamPaperPic";
      } else {
        uploadedName = "uploadedElectronicPaper";
        uploadingName = "uploadingElectronicPaper";
      }
      if (!this.uploadPictureData[examineeId][uploadedName]) {
        this.$set(this.uploadPictureData[examineeId], uploadedName, []);
      }
      this.uploadPictureData[examineeId][uploadedName] = [];
      console.log(this.uploadPictureData[examineeId]);
      this.uploadPictureData[examineeId][uploadingName].forEach((item) => {
        this.uploadPictureData[examineeId][uploadedName].push({
          name: item.name,
          size: item.size,
        });
      });
      this.uploadingPic = [];
      this.uploadPictureData[examineeId][uploadingName] = [];
      this.handleUploadPic = this.uploadPictureData[examineeId][uploadedName];
      this.$forceUpdate();
    },
  },
  filters: {
    dayList(val) {
      return val ? dayjs(val).format("YYYY-MM-DD HH:mm") : "";
    },
    subjectTypeFilters(key) {
      switch (key) {
        case 1:
          return `物理`;
        case 2:
          return `生物`;
        case 3:
          return `化学`;
        default:
          return ``;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.seLabel {
  display: inline-block;
  width: 90px;
  text-align: center;
  color: rgb(96, 98, 102);
  background-color: rgb(220, 230, 236);
}

.button-container {
  text-align: center;
  margin-top: 20px;
}
::v-deep .upload-demo {
  .el-upload-dragger {
    width: 610px;
  }
}
</style>
